import React, { useEffect, useState } from 'react';
import { CALL_BY_SAME_PAIR_COLUMN } from '../../../constants/datatable-column';
import { useQuery } from '@tanstack/react-query';
import { samePairCall } from '../../../api/adapters/report';
import Loader from '../../pages/Loader/Loader';
import { notify } from '../../../utils/helpers/notification.helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import ReportPagination from './ReportPagination';
import { Form, InputGroup } from 'react-bootstrap';

const CallBySamePair = () => {
    const pageSize = 10;
    const [queryData, setQueryData] = useState({
        page: 1,
        limit: pageSize,
        search: '',
    });
    const [reportError, setReportError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: viewSamePairCallList,
        isLoading: isLoadingSamePairCallList,
        error: queryError,
    } = useQuery({
        queryKey: ['viewSamePairCallList', queryData],
        queryFn: () => samePairCall(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setReportError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (reportError) {
            notify('error', reportError.message);

            if (reportError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setReportError(null);
    }, [reportError]);

    const handlePageChange = (newPage) => {
        setQueryData((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            search: value,
        }));
    };

    return (
        <>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Call By Same Pair</h4>
                        <div className='d-flex'>
                            <div className='mx-3'>
                                <InputGroup>
                                    <>
                                        <InputGroup.Text>
                                            <i className='fa fa-search'></i>
                                        </InputGroup.Text>
                                        <Form.Control
                                            aria-label='With textarea'
                                            style={{
                                                position: 'relative',
                                                zIndex: 0,
                                            }}
                                            placeholder='Search by user name'
                                            value={queryData?.search}
                                            onChange={(e) => handleSearch(e)}
                                        />
                                    </>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='table-responsive'>
                            <div
                                id='example_wrapper'
                                className='dataTables_wrapper'
                            >
                                <table
                                    id='example'
                                    className='display w-100 dataTable '
                                >
                                    <thead>
                                        <tr>
                                            {CALL_BY_SAME_PAIR_COLUMN.map(
                                                (d, i) => (
                                                    <th key={i} scope='col'>
                                                        {d}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    </thead>
                                    {isLoadingSamePairCallList ? (
                                        <div
                                            className='card-body d-flex align-items-center justify-content-center'
                                            style={{
                                                position: 'relative',
                                                top: '50%',
                                                left: '100%',
                                            }}
                                        >
                                            <Loader />
                                        </div>
                                    ) : (
                                        <tbody>
                                            {viewSamePairCallList?.data?.map(
                                                (item, i) => (
                                                    <tr
                                                        className='odd'
                                                        role='row'
                                                        key={item._id}
                                                    >
                                                        <td className='sorting_1 '>
                                                            {item?.callerName
                                                                ? (item
                                                                      ?.callerName
                                                                      ?.sFirstName ||
                                                                      '') +
                                                                  (item
                                                                      ?.callerName
                                                                      ?.sMiddleName
                                                                      ? ' ' +
                                                                        item
                                                                            .callerName
                                                                            .sMiddleName
                                                                      : '') +
                                                                  (item
                                                                      ?.callerName
                                                                      ?.sLastName
                                                                      ? ' ' +
                                                                        item
                                                                            .callerName
                                                                            .sLastName
                                                                      : '')
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            {item?.recipientName
                                                                ? (item
                                                                      ?.recipientName
                                                                      ?.sFirstName ||
                                                                      '') +
                                                                  (item
                                                                      ?.recipientName
                                                                      ?.sMiddleName
                                                                      ? ' ' +
                                                                        item
                                                                            .recipientName
                                                                            .sMiddleName
                                                                      : '') +
                                                                  (item
                                                                      ?.recipientName
                                                                      ?.sLastName
                                                                      ? ' ' +
                                                                        item
                                                                            .recipientName
                                                                            .sLastName
                                                                      : '')
                                                                : ''}
                                                        </td>
                                                        <td>{item?.count}</td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    )}
                                </table>
                                <hr />

                                <ReportPagination
                                    currentPage={
                                        viewSamePairCallList?.pagination?.page
                                    }
                                    totalPages={
                                        viewSamePairCallList?.pagination
                                            ?.totalPages
                                    }
                                    handlePageChange={handlePageChange}
                                    queryData={viewSamePairCallList?.pagination}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CallBySamePair;
