import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@tanstack/react-query';
import { redeemRewards } from '../../../api/adapters/report';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';

// Register the necessary components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const RedeemRewards = () => {
    const [reportError, setReportError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: viewRedeemRewards,
        isLoading: isLaodingRedeemRewards,
        error: queryError,
    } = useQuery({
        queryKey: ['viewSamePairCallList'],
        queryFn: () => redeemRewards().then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setReportError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (reportError) {
            notify('error', reportError.message);

            if (reportError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setReportError(null);
    }, [reportError]);

    // const labels =
    //     viewRedeemRewards?.data?.map((item) => item.rewardDescription) || [];
    const labels =
        viewRedeemRewards?.data?.map((item) => {
            const words = item.rewardDescription.split(' ');
            return words.length > 2
                ? `${words[0]} ${words[1]}`
                : item.rewardDescription;
        }) || [];

    const dataPoints =
        viewRedeemRewards?.data?.map((item) => item.totalRedemptions) || [];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Total Redemptions',
                // data: [25, 20, 60, 41, 66, 45, 80],
                data: dataPoints,
                borderColor: 'rgba(68, 129, 78,1)',
                borderWidth: 1,
                backgroundColor: 'rgba(68, 129, 78, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        // Return the full rewardDescription as the tooltip title
                        const index = context[0].dataIndex;
                        return viewRedeemRewards.data[index].rewardDescription;
                    },
                    label: function (context) {
                        // Return the totalRedemptions value as the tooltip label
                        const value = context.raw; // Access the data point value
                        return `Total Redemptions: ${value}`;
                    },
                },
            },
        },
        scales: {
            y: {
                // max: 100,
                // max: Math.max(...dataPoints, 50),
                max: dataPoints.length > 0 ? Math.max(...dataPoints) + 10 : 100,
                min: 0,
                // ticks: {
                //     beginAtZero: true,
                //     stepSize: 20,
                //     padding: 5,
                // },
                ticks: {
                    font: {
                        weight: 'bold', // Make y-axis labels bold
                    },
                },
            },
            x: {
                ticks: {
                    padding: 5,
                    font: {
                        weight: 'bold', // Make x-axis labels bold
                    },
                },
            },
        },
    };

    return (
        <>
            <Line data={data} options={options} width={'400px'} />
        </>
    );
};

export default RedeemRewards;
