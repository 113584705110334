export const UNIVERSITY_COLUMN = [
    'University Name',
    'City Name',
    'Country Name',
    'Status',
    'Actions',
];
export const RESIDENCE_COLUMN = [
    'Residence Name',
    'City Name',
    'Country Name',
    'Status',
    'Actions',
];

export const CITY_COLUMN = ['City Name', 'Country Name', 'Status', 'Actions'];

export const COUNTRY_COLUMN = [
    'Country Name',
    'Created At',
    'Status',
    'Actions',
];

export const KEYWORD_COLUMN = [
    'Chat Keyword',
    'Created At',
    'Status',
    'Actions',
];

export const PREFERENCE_COLUMN = [
    'Preference Category',
    'Created At',
    'Status',
    'Actions',
];

export const FAQ_COLUMN = ['Question', 'Answer', 'Actions'];

export const PENDING_USER_COLUMN = [
    'First Name',
    'Surname 1',
    'Surname 2',
    'Email',
    'Type',
    'Actions',
];

export const APPROVE_USER_COLUMN = [
    'First Name',
    'Surname 1',
    'Surname 2',
    'Email',
    'Type',
    'Talked Hours',
    'Ratings',
    'Status',
    'Disable Reason',
    'Actions',
];

export const REJECT_USER_COLUMN = [
    'First Name',
    'Surname 1',
    'Surname 2',
    'Email',
    'Type',
    'Rejected Reason',
    'Status',
    'Actions',
];

export const REWARD_COLUMN = [
    'Reward Icon',
    'Reward Title',
    'Reward Description',
    'Gift Codes',
    'Status',
    'Actions',
];

export const DIALOGAE_COLUMN = [
    'Start Date',
    'Start Time',
    'End Time',
    'Status',
    'Actions',
];

export const ANIMATION_TEXT_COLUMN = ['Title', 'Status', 'Actions'];

export const POP_UP_COLUMN = [
    {
        Header: 'Preference Item',
        accessor: 'id',
        sortType: 'basic',
    },
    {
        Header: 'Pop Up Text',
        accessor: 'sPopUpText',
    },
    {
        Header: 'Status',
        accessor: 'bIsActive',
    },
    {
        Header: 'Actions',
    },
];

export const FEEDBACK_COLUMN = [
    'User Name',
    'Email',
    'Contact Number',
    'Feedback',
    'Remark',
    'Date',
    'Status',
    'Actions',
];

export const CLAIMED_REWARD_COLUMN = [
    'User Name',
    'Reward Title',
    'Reward Image',
    'Gift Code',
    'Claimed Date',
    'Expiry Date',
];

export const UNCLAIMED_REWARD_COLUMN = ['Gift Code', 'Expiry Date', 'Action'];

export const REPORTED_USER_COLUMN = [
    'Reported User',
    'Reported By',
    'Message',
    'Status',
    'Remarks',
    'Date',
    'Contact Number',
    'Actions',
];

export const REWARD_REDEMPTION_COLUMN = [
    'User Name',
    'Redeemed Date',
    'Redeem Hours',
    'Reward Description',
];

export const MATCH_USER_COLUMN = [
    'User Name',
    'Email',
    'Count'
]

export const CALL_BY_SAME_PAIR_COLUMN = [
    'Caller Name',
    'Recipient Name',
    'Count'
]

export const REDEEM_REWARDS_COLUMN = []
