import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAverageAge } from '../../../api/adapters/report';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';

const AgeAverage = () => {
    // const [series] = useState([42, 47, 52, 58]);
    // const labels = ['VIP', 'Regular', 'Exclusive', 'Economic'];
    const colors = [
        '#709fba',
        '#ff5c00',
        '#44814e',
        '#3693ff',
        '#ffcc00',
        '#ff9999',
    ];
    const [reportError, setReportError] = useState(null);
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: viewAverageAge,
        // isLoading: isLaoding,
        error: queryError,
    } = useQuery({
        queryKey: ['viewAverageAge'],
        queryFn: () => getAverageAge().then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setReportError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (reportError) {
            notify('error', reportError.message);

            if (reportError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setReportError(null);
    }, [reportError]);

    useEffect(() => {
        if (viewAverageAge) {
            const counts = viewAverageAge?.ageRanges?.map((item) => item.count);
            const ranges = viewAverageAge?.ageRanges?.map((item) => item.range);
            setSeries(counts);
            setLabels(ranges);
        }
    }, [viewAverageAge]);
    const totalCount = series.reduce((a, b) => a + b, 0);
    const options = {
        chart: {
            type: 'polarArea',
            sparkline: {
                enabled: true,
            },
        },
        labels,
        fill: {
            opacity: 1,
            colors,
        },
        stroke: {
            stroke: false,
            width: 0,
            colors: ['transparent'],
        },
        yaxis: {
            show: false,
        },
        legend: {
            show: false, // Disable the default legend
        },
        plotOptions: {
            polarArea: {
                rings: {
                    strokeWidth: 0,
                },
            },
        },
        theme: {
            monochrome: {
                enabled: true,
                shadeTo: 'light',
                shadeIntensity: 0.6,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: (val, { seriesIndex }) => {
                return series[seriesIndex]; // Show the count in each section
            },
        },
    };

    return (
        <div>
            <div id='chart'>
                <ReactApexChart
                    options={options}
                    series={series}
                    type='polarArea'
                    height={600}
                />
            </div>
            {/* Custom Center Label */}
            {/* <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#000', // Adjust color as needed
                }}
            >
                {totalCount}
            </div> */}
            {/* Custom Legend */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                }}
            >
                {labels.map((label, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 10px',
                        }}
                    >
                        <div
                            style={{
                                width: '15px',
                                height: '15px',
                                backgroundColor: colors[index],
                                borderRadius: '50%',
                                marginRight: '5px',
                            }}
                        />
                        <span>{label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AgeAverage;
