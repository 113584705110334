import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@tanstack/react-query';
import { callCancelled } from '../../../api/adapters/report';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Register the necessary components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const CallCancelled = () => {
    const [reportError, setReportError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const period = 'month';
    const [period, setPeriod] = useState('month'); // State for period selection

    const {
        data: viewCallCancelled,
        // isLoading: isLaodingRedeemRewards,
        error: queryError,
    } = useQuery({
        queryKey: ['viewCallCancelled', period],
        queryFn: () => callCancelled(period).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setReportError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (reportError) {
            notify('error', reportError.message);

            if (reportError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setReportError(null);
    }, [reportError]);

    const labels =
        viewCallCancelled?.cancelledAppointments?.map((item) => item.period) ||
        [];
    const dataPoints =
        viewCallCancelled?.cancelledAppointments?.map((item) => item.count) ||
        [];

    // const labels =
    //     viewRedeemRewards?.data?.map((item) => item.rewardDescription) || [];
    // const labels =
    //     viewCallCancelled?.data?.map((item) => {
    //         const words = item.rewardDescription.split(' ');
    //         return words.length > 2
    //             ? `${words[0]} ${words[1]}`
    //             : item.rewardDescription;
    //     }) || [];

    // const dataPoints =
    //     viewCallCancelled?.data?.map((item) => item.totalRedemptions) || [];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Total Redemptions',
                // data: [25, 20, 60, 41, 66, 45, 80],
                data: dataPoints,
                borderColor: 'rgba(68, 129, 78,1)',
                borderWidth: 1,
                backgroundColor: 'rgba(68, 129, 78, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        // Return the full rewardDescription as the tooltip title
                        const index = context[0].dataIndex;
                        return viewCallCancelled.cancelledAppointments[index]
                            .period;
                    },
                    label: function (context) {
                        // Return the totalRedemptions value as the tooltip label
                        const value = context.raw; // Access the data point value
                        return `Total Redemptions: ${value}`;
                    },
                },
            },
        },
        scales: {
            y: {
                // max: 100,
                // max: Math.max(...dataPoints, 50),
                max: dataPoints.length > 0 ? Math.max(...dataPoints) + 5 : 100,
                min: 0,
                ticks: {
                    //     beginAtZero: true,
                    //     stepSize: 20,
                    //     padding: 5,
                    font: {
                        weight: 'bold', // Make x-axis labels bold
                    },
                },
            },
            x: {
                ticks: {
                    padding: 5,
                    font: {
                        weight: 'bold', // Make x-axis labels bold
                    },
                },
            },
        },
    };

    return (
        <>
            {/* <Line data={data} options={options} /> */}
            <div style={{ position: 'relative' }}>
                <Dropdown style={{ position: 'absolute', top: 20, right: 50 }}>
                    <Dropdown.Toggle
                        variant='primary'
                        id='dropdown-basic'
                        size='sm'
                    >
                        {period === 'month' ? 'Monthly' : 'Weekly'}
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ marginLeft: '5px' }}
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setPeriod('month')}>
                            Monthly
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setPeriod('week')}>
                            Weekly
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Line data={data} options={options} width={'400px'} />
            </div>
        </>
    );
};

export default CallCancelled;
