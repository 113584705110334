import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@tanstack/react-query';
import { getUserType } from '../../../api/adapters/report';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Register the necessary components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const UserType = () => {
    const [reportError, setReportError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const period = 'month';
    const [period, setPeriod] = useState('R'); // State for period selection

    const {
        data: viewUserType,
        // isLoading: isLaodingRedeemRewards,
        error: queryError,
    } = useQuery({
        queryKey: ['viewUserType', period],
        queryFn: () => getUserType(period).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setReportError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (reportError) {
            notify('error', reportError.message);

            if (reportError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setReportError(null);
    }, [reportError]);

    const labels = viewUserType?.map((item) => item.name) || [];
    const dataPoints = viewUserType?.map((item) => item.count) || [];

    // const labels = viewUserType?.map((item) => item.rewardDescription) || [];
    // const labels =
    //     viewUserType?.map((item) => {
    //         const words = item.rewardDescription.split(' ');
    //         return words.length > 2
    //             ? `${words[0]} ${words[1]}`
    //             : item.rewardDescription;
    //     }) || [];

    // const dataPoints = viewUserType?.map((item) => item.totalRedemptions) || [];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Total Users',
                // data: [25, 20, 60, 41, 66, 45, 80],
                data: dataPoints,
                borderColor: 'rgba(68, 129, 78,1)',
                borderWidth: 1,
                backgroundColor: 'rgba(68, 129, 78, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                titleFont: {
                    weight: 'bold', // Make tooltip title bold
                },
                bodyFont: {
                    weight: 'bold', // Make tooltip body bold
                },
            },
        },
        scales: {
            y: {
                // max: 100,
                // max: Math.max(...dataPoints, 50),
                max: dataPoints.length > 0 ? Math.max(...dataPoints) + 10 : 100,
                min: 0,
                ticks: {
                    font: {
                        weight: 'bold', // Make y-axis labels bold
                    },
                },
            },
            x: {
                ticks: {
                    padding: 5,
                    font: {
                        weight: 'bold', // Make x-axis labels bold
                    },
                },
            },
        },
    };

    return (
        <>
            {/* <Line data={data} options={options} /> */}
            <div style={{ position: 'relative' }}>
                <Dropdown style={{ position: 'absolute', top: 50, right: 80 }}>
                    <Dropdown.Toggle
                        variant='primary'
                        id='dropdown-basic'
                        size='sm'
                    >
                        {period === 'R' ? 'Residence' : 'University'}
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ marginLeft: '5px' }}
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setPeriod('S')}>
                            University
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setPeriod('R')}>
                            Residence
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Line data={data} options={options} width={'400px'} />
            </div>
        </>
    );
};

export default UserType;
