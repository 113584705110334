import Metismenu from 'metismenujs';
import React, { Component, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { ThemeContext } from '../../../context/ThemeContext';
import profile from '../../../assets/images/user.jpg';
import LogoutPage from '../Nav/Logout';
import { useSelector } from 'react-redux';

class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new Metismenu(this.$el);
    }
    componentWillUnmount() {}
    render() {
        return (
            <div className='mm-wrapper'>
                <ul className='metismenu' ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const Sidebar = () => {
    const oConnectedUser = useSelector((state) => state.connectedUser);

    const { iconHover, sidebarposition, headerposition, sidebarLayout } =
        useContext(ThemeContext);

    let path = window.location.pathname;
    path = path.split('/');
    path = path[path.length - 1];

    let dashBoard = [''],
        user = ['user-management', 'pending', 'accepted', 'rejected'],
        university = ['university'],
        residence = ['residence'],
        category = ['category'],
        pop = ['video-call-booster'],
        keyword = ['chat-keyword'],
        country = ['country'],
        city = ['city'],
        reward = ['reward', 'redemption'],
        report = [
            'count-match-user',
            'callBy-same-pair',
            'user-average',
            'rewards-redeemed',
            'call-cancelled',
            'call-duration',
            'age-average',
        ],
        faqs = ['faqs'],
        animation = ['animation-text'],
        dialogae = ['dialogae'],
        feedback = ['feedback'],
        reportedUser = ['reported-user'];

    return (
        <>
            <div
                className={`dlabnav ${iconHover} ${
                    sidebarposition.value === 'fixed' &&
                    sidebarLayout.value === 'horizontal' &&
                    headerposition.value === 'static'
                }`}
            >
                <PerfectScrollbar className='dlabnav-scroll'>
                    <Dropdown className='dropdown header-profile2'>
                        <Dropdown.Toggle
                            variant=''
                            as='a'
                            className='nav-link i-false c-pointer'
                        >
                            <div className='header-info2 d-flex align-items-center border'>
                                {oConnectedUser.sProfilePicUrl ? (
                                    <img
                                        src={`${oConnectedUser.sProfilePicUrl}?cacheblock=true`}
                                        width={20}
                                        alt=''
                                    />
                                ) : (
                                    <img src={profile} width={20} alt='' />
                                )}
                                <div className='d-flex align-items-center sidebar-info'>
                                    <div>
                                        <span className='font-w700 d-block '>
                                            {oConnectedUser.sUserName}
                                        </span>
                                    </div>
                                    <i className='fas fa-sort-down '></i>
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            align='right'
                            className=' dropdown-menu dropdown-menu-end'
                        >
                            <Link
                                to='/admin-profile/profile'
                                className='dropdown-item ai-icon'
                            >
                                <svg
                                    id='icon-user1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='text-primary me-1'
                                    width={18}
                                    height={18}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
                                    <circle cx={12} cy={7} r={4} />
                                </svg>
                                <span className='ms-2'>Profile </span>
                            </Link>

                            <LogoutPage />
                        </Dropdown.Menu>
                    </Dropdown>

                    <MM className='metismenu' id='menu'>
                        {/* dashBoard link */}
                        <li
                            className={`${
                                dashBoard.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link to='/'>
                                <i className='mdi mdi-view-dashboard'></i>
                                <span className='nav-text'>Dashboard</span>
                            </Link>
                        </li>

                        {/* user link */}
                        <li
                            className={`${
                                user.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link
                                className=' ai-icon'
                                to='/user-management/pending'
                            >
                                <i className='fa fa-users'></i>
                                <span className='nav-text'>User</span>
                            </Link>
                        </li>

                        {/* country link */}
                        <li
                            className={`${
                                country.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/country'>
                                <i className='fas fa-solid fa-globe'></i>
                                <span className='nav-text forms'>Country</span>
                            </Link>
                        </li>

                        {/* city link */}
                        <li
                            className={`${
                                city.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/city'>
                                <i className='fas fa-solid fa-city'></i>
                                <span className='nav-text forms'>City</span>
                            </Link>
                        </li>

                        {/* university link */}
                        <li
                            className={`${
                                university.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/university'>
                                <i className='fas fa fa-university'></i>
                                <span className='nav-text'>University</span>
                            </Link>
                        </li>

                        {/* residence link */}
                        <li
                            className={`${
                                residence.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/residence'>
                                <i className='fas fa fa-home'></i>
                                <span className='nav-text'>Residence</span>
                            </Link>
                        </li>

                        {/* dialogae now link */}
                        <li
                            className={`${
                                dialogae.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/dialogae'>
                                <i className='mdi mdi-rotate-3d'></i>
                                <span className='nav-text'>Dialogae Now</span>
                            </Link>
                        </li>

                        {/* preference link */}
                        <li
                            className={`${
                                category.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link
                                className=' ai-icon'
                                to='/preference/category'
                            >
                                <i className='fas fa fa-sitemap'></i>
                                <span className='nav-text'>Preference</span>
                            </Link>
                        </li>

                        {/* pop up link */}
                        <li
                            className={`${
                                pop.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/video-call-booster'>
                                <i className='fas fa-clone'></i>
                                <span className='nav-text'>
                                    Video Call Boosters
                                </span>
                            </Link>
                        </li>

                        {/* chat keyword link */}
                        <li
                            className={`${
                                keyword.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link to='/chat-keyword' className='ai-icon'>
                                <i className='lar la-comment-dots'></i>
                                <span className='nav-text'>Chat Keyword</span>
                            </Link>
                        </li>

                        {/* feedback link */}
                        <li
                            className={`${
                                feedback.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link to='/feedback' className='ai-icon'>
                                <i className='lar la-comment-dots'></i>
                                <span className='nav-text'>Feedback</span>
                            </Link>
                        </li>

                        {/* reward link */}
                        <li
                            className={`${
                                reward.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className='has-arrow ai-icon' to='#'>
                                <i className='fas fa-solid fa-medal'></i>

                                <span className='nav-text'>Reward</span>
                            </Link>
                            <ul>
                                <li
                                    className={`${
                                        path === 'reward' ? 'mm-active' : ''
                                    }`}
                                >
                                    <Link to='/category/reward'>
                                        Reward Management
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        path === 'redemption' ? 'mm-active' : ''
                                    }`}
                                >
                                    <Link to='/reward/redemption'>
                                        {' '}
                                        Redemption History
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {/* Reported User link */}
                        <li
                            className={`${
                                reportedUser.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link to='/reported-user' className='ai-icon'>
                                <i className='lar la-comment-dots'></i>
                                <span className='nav-text'>Report User</span>
                            </Link>
                        </li>

                        {/* animation text link */}
                        <li
                            className={`${
                                animation.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/animation-text'>
                                <i className='fa fa-solid fa-font'></i>
                                <span className='nav-text'>Animation Text</span>
                            </Link>
                        </li>

                        {/* faqs link */}
                        <li
                            className={`${
                                faqs.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link className=' ai-icon' to='/faqs'>
                                <i className='fas fa-clone'></i>
                                <span className='nav-text'>FAQs</span>
                            </Link>
                        </li>

                        {/* report link */}
                        <li
                            className={`${
                                report.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            {/* <Link className=' ai-icon' to='#'>
                                <i className='fas fa-solid fa-chart-line'></i>
                                <span className='nav-text'>Report</span>
                            </Link> */}
                            <Link className='has-arrow ai-icon' to='#'>
                                <i className='fas fa-solid fa-chart-line'></i>

                                <span className='nav-text'>Report</span>
                            </Link>
                            <ul>
                                <li
                                    className={`${
                                        path === 'count-match-user'
                                            ? 'mm-active'
                                            : ''
                                    }`}
                                >
                                    <Link to='/report/count-match-user'>
                                        Count Match User
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        path === 'callBy-same-pair'
                                            ? 'mm-active'
                                            : ''
                                    }`}
                                >
                                    <Link to='/report/callBy-same-pair'>
                                        {' '}
                                        Call By Same pair
                                    </Link>
                                </li>

                                <li
                                    className={`${
                                        path === 'user-average'
                                            ? 'mm-active'
                                            : ''
                                    }`}
                                >
                                    <Link to='/report/user-average'>
                                        {' '}
                                        Residence-University Average
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        path === 'rewards-redeemed'
                                            ? 'mm-active'
                                            : ''
                                    }`}
                                >
                                    <Link to='/report/rewards-redeemed'>
                                        {' '}
                                        Rewards Redeemed
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        path === 'call-cancelled'
                                            ? 'mm-active'
                                            : ''
                                    }`}
                                >
                                    <Link to='/report/call-cancelled'>
                                        {' '}
                                        Call Cancelled
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        path === 'call-duration'
                                            ? 'mm-active'
                                            : ''
                                    }`}
                                >
                                    <Link to='/report/call-duration'>
                                        {' '}
                                        Call Durations
                                    </Link>
                                </li>
                                <li
                                    className={`${
                                        path === 'age-average'
                                            ? 'mm-active'
                                            : ''
                                    }`}
                                >
                                    <Link to='/report/age-average'>
                                        {' '}
                                        Average Age
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </MM>
                </PerfectScrollbar>
            </div>
        </>
    );
};

export default Sidebar;
