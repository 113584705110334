import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@tanstack/react-query';
import { getCallDuration } from '../../../api/adapters/report';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import ReactApexChart from 'react-apexcharts';

// Register the necessary components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const CallDuration = () => {
    const [reportError, setReportError] = useState(null);
    const [series, setSeries] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: viewCallDuration,
        // isLoading: isLaodingRedeemRewards,
        error: queryError,
    } = useQuery({
        queryKey: ['viewCallDuration'],
        queryFn: () => getCallDuration().then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setReportError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (reportError) {
            notify('error', reportError.message);

            if (reportError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setReportError(null);
    }, [reportError]);

    useEffect(() => {
        if (viewCallDuration) {
            // Extract data for the chart
            const appointmentCalls = viewCallDuration.appointmentCalls;
            const dialogueNow = viewCallDuration.dialogueNow;

            const dates = appointmentCalls.map((call) => call.date);
            const appointmentCounts = appointmentCalls.map(
                (call) => call.count
            );
            const dialogueCounts = dialogueNow.map(
                (dialogue) => dialogue.count
            );

            const appointmentAverageCount = appointmentCalls.map(
                (call) => call.avgDuration
            );
            const dialogueAverageCount = dialogueNow.map(
                (call) => call.avgDuration
            );

            // Update the series for the chart
            setSeries([
                {
                    name: 'Appointment Calls',
                    data: appointmentCounts,
                    average: appointmentAverageCount,
                },
                {
                    name: 'Dialogue Now Calls',
                    data: dialogueCounts,
                    average: dialogueAverageCount,
                },
            ]);
            setOptions((prevOptions) => ({
                ...prevOptions,
                xaxis: {
                    categories: dates,
                },
            }));
        }
    }, [viewCallDuration]);

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: 600,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            fontSize: '12px',
            fontWeight: 'bold',
            labels: {
                colors: 'black',
            },
            position: 'bottom',
            horizontalAlign: 'center',
            markers: {
                width: 19,
                height: 19,
                strokeWidth: 0,
                radius: 19,
                strokeColor: '#fff',
                fillColors: ['#44814e', '#709fba'], // Green for Appointment Calls, Blue for Dialogue Now
                offsetX: 0,
                offsetY: 0,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#3e4954',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: [], // Will be set dynamically based on data
            labels: {
                style: {
                    fontWeight: 'bold',
                },
            },
        },
        fill: {
            colors: ['#44814e', '#709fba'], // Green for Appointment Calls, Blue for Dialogue Now
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val; // Adjust tooltip formatting if necessary
                },
            },
        },
    });

    return (
        <>
            <div>
                <ReactApexChart
                    options={options}
                    series={series}
                    type='bar'
                    height={600}
                />
            </div>
        </>
    );
};

export default CallDuration;
