import React from 'react';

import { Link } from 'react-router-dom';

const ReportPagination = ({
    currentPage,
    totalPages,
    handlePageChange,
    queryData,
}) => {
    return (
        <div className='d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2'>
            <div className='dataTables_info'>
                Showing {queryData?.start}
                {'  '}
                to {queryData?.end} of {queryData?.total} entries
            </div>
            <div
                className='dataTables_paginate paging_simple_numbers mb-0'
                id='example5_paginate'
            >
                <Link
                    className={`paginate_button previous ${
                        currentPage === 1 ? 'disabled' : ''
                    }`}
                    onClick={() =>
                        currentPage - 1 > 0 && handlePageChange(currentPage - 1)
                    }
                >
                    <i
                        className='fa fa-angle-double-left'
                        aria-hidden='true'
                    ></i>
                </Link>

                <span>
                    {totalPages <= 4 ? (
                        // Show all pages if there are 3 or fewer pages
                        Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                className={`paginate_button d-inline-block ${
                                    currentPage === index + 1 ? 'current' : ''
                                }`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))
                    ) : (
                        // Show 1, 2, and last page for more than 3 pages
                        <>
                            {currentPage === 2 && (
                                <button
                                    className={`paginate_button d-inline-block ${
                                        currentPage === 1 ? 'current' : ''
                                    }`}
                                    onClick={() => handlePageChange(1)}
                                >
                                    1
                                </button>
                            )}
                            {currentPage > 2 && (
                                <>
                                    <button
                                        className={`paginate_button d-inline-block ${
                                            currentPage === 1 ? 'current' : ''
                                        }`}
                                        onClick={() => handlePageChange(1)}
                                    >
                                        1
                                    </button>
                                    {currentPage > 3 && (
                                        <button
                                            className='paginate_button d-inline-block ellipsis'
                                            disabled
                                        >
                                            ...
                                        </button>
                                    )}
                                    <button
                                        className={`paginate_button d-inline-block ${
                                            currentPage === currentPage - 1
                                                ? 'current'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            handlePageChange(currentPage - 1)
                                        }
                                    >
                                        {currentPage - 1}
                                    </button>
                                </>
                            )}

                            <button
                                className={`paginate_button d-inline-block ${
                                    currentPage ? 'current' : ''
                                }`}
                                onClick={() => handlePageChange(currentPage)}
                            >
                                {currentPage}
                            </button>
                            {currentPage < totalPages - 1 && (
                                <>
                                    <button
                                        className={`paginate_button d-inline-block ${
                                            currentPage === currentPage + 1
                                                ? 'current'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            handlePageChange(currentPage + 1)
                                        }
                                    >
                                        {currentPage + 1}
                                    </button>
                                    {currentPage < totalPages - 2 && (
                                        <button
                                            className='paginate_button d-inline-block ellipsis'
                                            disabled
                                        >
                                            ...
                                        </button>
                                    )}
                                </>
                            )}
                            {currentPage !== totalPages && (
                                <button
                                    className={`paginate_button d-inline-block ${
                                        currentPage === totalPages
                                            ? 'current disabled'
                                            : ''
                                    }`}
                                    onClick={() => handlePageChange(totalPages)}
                                >
                                    {totalPages}
                                </button>
                            )}
                        </>
                    )}
                </span>

                <Link
                    className={`paginate_button next ${
                        currentPage === totalPages ? 'disabled' : ''
                    }`}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    <i
                        className='fa fa-angle-double-right'
                        aria-hidden='true'
                    ></i>
                </Link>
            </div>
        </div>
    );
};
export default ReportPagination;
