import apiClient from "../client/api-client";

export const matchUserList = async (data) => {
    const url = `admin/reports/matchUser/list?page=${data.page}&limit=${data.limit}`; return apiClient({
        url,
        data: { search: data?.search },
        method: 'POST',
    });
}

export const samePairCall = async (data) => {
    const url = `admin/reports/same-pair-call/list?page=${data.page}&limit=${data.limit}`;
    return apiClient({
        url,
        data: { search: data?.search },
        method: 'POST',
    });
}

export const redeemRewards = async (data) => {
    const url = `admin/reports/rewards/count`;
    return apiClient({
        url,
        method: 'GET',
    });
}

export const callCancelled = async (data) => {
    const url = `admin/reports/call/cancel?period=${data}`;
    return apiClient({
        url,
        method: 'GET',
    });
}

export const getUserType = async (data) => {
    const url = `admin/reports/userType/count?type=${data}`;
    return apiClient({
        url,
        method: 'GET',
    });

}

export const getCallDuration = async () => {
    const url = 'admin/reports/call/duration/get'
    return apiClient({
        url,
        method: 'GET',
    });
}

export const getAverageAge = async () => {
    const url = 'admin/reports/user/age/get'
    return apiClient({
        url,
        method: 'GET',
    });
}